@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", serif;
  scroll-behavior: smooth;
}

.font-montserrat {
  font-family: "Montserrat", serif;
}

a {
  text-decoration: none;
}
.background-body {
  background: #e0dee6;
}
.background-footer {
  background: #fff;
  box-shadow: 0px 1px 5px #baadb0;
  width: 87%;
  margin: auto;
}
.content-title h1 {
  font-size: 1.5rem;
  color: #007e5f;
  font-weight: 400;
  text-align: center;
  padding: 20px 5px 5px 5px;
}
.content-title {
  width: 100%;
}
.content-title-gach {
  width: 10%;
  background: rgb(180, 16, 16);
  height: 2.5px;
  margin: auto;
}
.font-lato {
  font-family: "Lato", sans-serif;
}
.text-primary-color {
  color: #003985;
}
.bg-primary-color {
  background: #003985;
}
.border-primary-color {
  border-color: #003985;
}
.bg-opacity-80{
  opacity: 80;
}
.text-secondary-color {
  color: #5cc4e4;
}
.bg-secondary-color {
  background: #5cc4e4;
}
.border-secondary-color {
  border-color: #5cc4e4;
}
.alert-wrapper-custom-scroll-bar::-webkit-scrollbar {
  width: 4px;
}

.alert-wrapper-custom-scroll-bar::-webkit-scrollbar-track {
  background: #d9d9d9;
  border-radius: 10px;
}

.alert-wrapper-custom-scroll-bar::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0);
  border-radius: 10px;
}

.alert-wrapper-custom-scroll-bar::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.66);
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #004caf;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #00142e;
}

.rec-slider-container {
  /* override react-elastic-carousel*/
  margin: 0 !important;
}

.animation-shake {
  animation: shake 1s linear infinite;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.box-shadow-3d {
  box-shadow: 2px 2px 0px #5cc4e4, 4px 4px 0px #5cc4e4, 6px 6px 0px #5cc4e4,
    8px 8px 0px #5cc4e4, 10px 10px 0px #5cc4e4, 12px 12px 0px #5cc4e4;
}

.bg-blur {
  backdrop-filter: blur(6px);
}

.bg-linear-primary {
  background: rgb(76,218,255);
  background: linear-gradient(170deg, rgba(76,218,255,1) 0%, rgba(170,57,255,1) 100%);
}

.bg-linear-secondary {
  background: rgb(58,180,170);
  background: linear-gradient(164deg, rgba(58,180,170,1) 0%, rgba(0,50,172,1) 51%, rgba(18,111,149,1) 100%);
}

.overflow-overlay {
  overflow: overlay;
}

.bg-home {
  background: rgb(29,131,184);
  background: radial-gradient(circle, rgba(29,131,184,1) 0%, rgba(56,116,222,1) 84%, rgba(70,83,252,1) 100%);
}

@import url(//fonts.googleapis.com/css?family=Lato:300:400);

.header {
  position:relative;
  background: linear-gradient(60deg, rgb(44, 32, 207) 0%, rgb(0, 195, 221) 100%);
  color:white;
}
.logo {
  width:50px;
  fill:white;
  padding-right:15px;
  display:inline-block;
  vertical-align: middle;
}

.inner-header {
  height:65vh;
  width:100%;
  margin: 0;
  padding: 0;
}

.waves {
  position:relative;
  width: 100%;
  height:15vh;
  margin-bottom:-7px; /*Fix for safari gap*/
  min-height:100px;
  max-height:150px;
}

.content {
  position:relative;
  height:20vh;
  text-align:center;
  background-color: white;
}

/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
   transform: translate3d(-90px,0,0);
  }
  100% { 
    transform: translate3d(85px,0,0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height:40px;
    min-height:40px;
  }
  .content {
    height:30vh;
  }
  h1 {
    font-size:24px;
  }
}

.hide-input-number-arrow::-webkit-outer-spin-button, .hide-input-number-arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
}
verify-viewer{
  width: 100%;
  height: 100%;
}

@keyframes bound-out {
  0% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.8);
    box-shadow: 5px 5px 10px 1px #5cc4e4;
  }
  100% {
    transform: scale(1);
  }
}

.animation-bound-out {
  animation: bound-out 2s infinite;
}