.background-content {
    background: #dbf1eb;
    width: 70%;
    margin: auto;
    border-radius: 10px;
}
.background-content > h3 {
    text-align: left;
    padding: 5px 10px;
    border-bottom: 1px dashed #b41010;
    margin: 5px;
    font-size: 0.99rem;
}
.content-sp{
    display: grid;
    grid-gap: 35px;
    grid-template-columns: repeat(auto-fit, 31.3%);
    margin: auto;
    width: 90%;
    padding: 0px 0 40px 0;
   
}
.card-content {
    box-shadow: 0px 1px 5px #baadb0;
    background: rgb(247 247 247); 
    display: grid;
    align-content: space-between;
    border-radius: 7px;
    margin-top: 45px;
    cursor: pointer;
    text-align: center;
}
.card-content-active {
    box-shadow: 0px 1px 5px #baadb0;
    background: #fff; 
    display: grid;
    align-content: space-between;
    border-radius: 7px;
    border: 2px solid #007e5f;
    
    
}
.selected {
    display: none;
    align-content: space-between;
    opacity: 1.5;
    border: 1px solid red;
    border-radius: 7px;
    box-shadow: 0px 1px 5px #baadb0;
    background: #fff;
}
/* .card-content:hover {
    border: 2px solid #007e5f;
    cursor: pointer;
} */
.card-content-up {
    margin-bottom: 10px;
}
.card-content-up > h3 {
    font-size: 1.07rem;
    margin: 15px 25px 7px;
    color: #007e5f;
}
.card-content  p {
    color: #a7a5bd;
    font-size: 0.8rem;
    text-align: center;
    margin: 0 14px 5px;
}
.card-content-down {
    padding-bottom: 10px;
}
.card-content-down h4 {
    text-align: center;
    color: rgb(180, 16, 16);
    padding-bottom: 5px;
    font-size: 0.9rem;
   
}
.content-container.content-container-row {
    padding: 40px;
}

.content-container-details {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 15px;
}
.content-container-details h3 {
    text-align: right;
    font-size: 1.1rem;
    font-weight: 400;
    justify-content: right;
    padding-right: 10px;
}

.content-container-details div{
    text-align: left;
    justify-content: left;
    margin-left: 15px;
}
/* .content-detail > span,p{
    font-size: 0.75rem;
    font-weight: 400;
    display: block;
    color: #ceae56;
} */
/* .content-detail > p {
    font-size: 0.73rem;
} */
.sel-br {
    border-radius: 5px;
    border: none;
    height: 25px;
    width: 25%;
    font-size: 0.9rem;
    color: #a7a5bd;
}
.btn-continue {
    justify-content: center;
    text-align: center;
    align-items: center;
}
.btn-continue button {
    width: 21%;
    height: 39px;
    outline: none;
    border: none;
    background: rgb(180, 16, 16);
    border-radius: 5px 0px;
    font-size: 1.3rem;
    margin: 20px;
    cursor: pointer;
}
.btn-continue a {
    color: #fff;
}
.camera-br {
    border: 1px dashed #007e5f;
}
.info-regis {
    display: grid;
    width: 90%;
    margin: auto;
    gap: 10px;
    padding-top: 10px;
}
.info-regis h4 {
    color: #007e5f;
    font-size: 0.97rem;
}
.info-regis input {
    font-size: 0.95rem;
    border-radius: 5px;
    border: 1.59px solid #007e5f;
    padding: 5px;
}
.info-regis input:focus {
    border: 1px solid #e3f547;
}
.btn-time {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 50%;
    margin: auto;
    padding: 10px;
    gap: 35px;
}
.btn-time .btn-t{
    font-size: 1.1rem;
    height: 40px;
    padding: 5px;
    background: rgb(247 247 247);
    border: 1.5px solid #909895;
    cursor: pointer;
    border-radius: 5px;
    opacity: 0.8;
    font-weight: 600;
}
.btn-time .btn-active {
    border: 2px solid #007e5f;
    background: #fff;
    opacity: 1;
    border-radius: 5px;
    font-size: 1.1rem;
    height: 40px;
    padding: 5px;
}
.info-time {
    display: grid;
    grid-template-columns: auto auto;
    width: 50%;
    margin: auto;
    padding: 25px 0;
    text-align: center;
    font-weight: 600;
}
.background-content > .inter-hd {
    border: none;
}
.inter-hd-info {
    padding: 0 25px;
}
.pay-hd-info {
    padding: 20px 25px;
}
.pm-t {
    border-radius: 0 0 10px 10px;
    padding: 15px 3%;
    box-shadow: 0px 0px 2px #baadb0;
}
.payment-total {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    margin: 10px 0;
}
.payment-total h3 {
    font-size: 1.08rem;
    font-weight: 600;
    color: #fff;
}
.pm-t button {
        width: 100%;
        height: 35px;
        outline: none;
        border: none;
        border-radius: 5px;
        font-size: 1.3rem;
        margin: auto;
        cursor: pointer;
        background: rgb(180, 16, 16);
}
.pm-t a {
    color: #fff;
}

/* ============= MEDIA QUERIES (SMALL DEVICES) ==========*/
@media screen and (max-width: 1024px) {
    .content-sp {        
        grid-template-columns: none;
        display: block;
        
    }
    .card-content {
        margin-top: 10px;
    }
    .background-content {
        width: 90%;
        
    }
    .content-title h1 {
        font-size: 1.15rem;
    }
    .img-br {
        max-width: 100%;
        height: 100px;
        background-repeat: no-repeat;
        background-size: contain;
    }
    .btn-time {
        width: auto;
    }
    .info-time {
        width: auto;
    }
}