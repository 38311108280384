.navbar-header {
    display: grid;
    box-shadow: 0px 1px 5px #baadb0;
    position: relative;

    grid-template-columns: auto auto;
}

.navbar-header a {
    color: #007e5f;
}
.navbar-right {
    width: 100%;
}
.navbar-logo {
    /* background: url("../assets/photo_2022-09-05_12-00-26.webp"); */
    max-width: 100%;
    height: 77px;
    background-repeat: no-repeat;
    background-size: contain;
}
.navbar-content {
    display: flex;
    align-content: space-between;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.navbar-center{
    width: 70%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
    font-size: 1rem;


    
}
.navbar-center-content{
    display: flex;
    align-items: center;
    gap: 3%;
    padding: 0% 1%;
}
.navbar-center-content:hover {
    background: #f5f5f5;
    border-radius: 33px;
    cursor: pointer;
}
.navbar-center-icon {
    border-radius: 999px; 
    background: #f0f0f0;
}
.navbar-assist-content{
    margin: 5px 10px;
}
.navbar-assist-content > p{
    font-size: 13px;
    padding-top: 3px;
    color: #9c8c8c;
}

.navbar-translate{
    /* width: 15%; */
    text-align: center;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.hamburger {
    display: none;
}
/* ============= MEDIA QUERIES (SMALL DEVICES) ==========*/
@media screen and (max-width: 1024px) {
    .navbar-content {
        justify-content: center;
        align-items: center;
        width: 100%;
        /* background: rgba(255, 255, 255, 0.98); */
        position: absolute;
        top: -300vh;
        left: 0;
        right: 0;
        z-index: 3;
        transition: 0.3s;
    }
    .navbar-content.active{
        top: 100%;
    }
    .hamburger {
        display: flex;
        justify-content: end;
        padding: 20px;
    }
    .navbar-assist-content > p {
        font-size: 0.7rem;
    }
    .navbar-center {
        display: block;
        margin: auto;
    }
    .navbar-content {
        display: block;
    }
}
