.item-container{
    pointer-events: none;
    transition: all 0.45s ease;
}
.item-content{
    pointer-events: auto;
    cursor: pointer;
}
.item-container:hover{
    background-color: #f5f5f5;
}